<template>
	<div class="" style="min-height: 100vh;background-color: var(--theme-bg-color);">
		<withdrawHeader @modelValue="modelValue" :tbaName='current' >
			<template v-slot:conter="slotProps">
				<Solicitarsaque 
				 :current="current"
				 :withdraw="withdraw"
				 :modelValue="modelValue"
				 :getBank="getBank"
				 :contaDeLevantamento="contaDeLevantamento"
				 :contaDeLevantamentoFromDta="contaDeLevantamentoFromDta"
				 v-show="slotProps.tabIndex==0"></Solicitarsaque>
				 <TarefasDeApostas
				 :current="current"
				 :withdraw="withdraw"
				 :modelValue="modelValue"
				 :getBank="getBank"
				 :animate="animate"
				 :contaDeLevantamento="contaDeLevantamento"
				 :contaDeLevantamentoFromDta="contaDeLevantamentoFromDta"
				 v-show="slotProps.tabIndex==1"
				 >
				 </TarefasDeApostas>
				 
				 <ContaParaRecebimento
				 :current="current"
				 :getBank="getBank"
				 :withdraw="withdraw"
				 :contaDeLevantamentoFromDta="contaDeLevantamentoFromDta"
				 :contaDeLevantamento="contaDeLevantamento"
				 :modelValue="modelValue"
				 v-show="slotProps.tabIndex==2"
				 ></ContaParaRecebimento>
				 <RegistroDeSaques
				 :current="current"
				 :getBank="getBank"
				 :withdraw="withdraw"
				 :contaDeLevantamentoFromDta="contaDeLevantamentoFromDta"
				 :contaDeLevantamento="contaDeLevantamento"
				 :modelValue="modelValue"
				 v-show="slotProps.tabIndex==3"
				 >
				 </RegistroDeSaques>
			</template>
		</withdrawHeader>
	</div>
</template>
<script>
	import {
		getBank,
		withdraw
	} from "@/apis/modules/login.js"
	// @ is an alias to /src
	import withdrawHeader from "@/components/withdrawHeader/index.vue"
	import antInputPassword from "@/components/ant-input-password/index.vue"
	import antS from "./ant-s.vue"
	import antInput from "./input.vue"
	import Solicitarsaque from "./Solicitarsaque.vue"
	import ContaParaRecebimento from "./ContaParaRecebimento.vue"
	import TarefasDeApostas from "./TarefasDeApostas.vue"
	import RegistroDeSaques from "./RegistroDeSaques.vue"
	import { Toast } from 'vant';
	
	export default {
		name: 'Home',
		components: {
			withdrawHeader,
			antInputPassword,
			antS,
			antInput,
			Solicitarsaque,
			ContaParaRecebimento,
			TarefasDeApostas,
			RegistroDeSaques
		},
		data() {
			return {
				list: [],
				current:0,
				isShowNum: false,
				loading: false,
				finished: false,
				contaDeLevantamento:true,
				contaDeLevantamentoFromDta:{},
				new_password: '',
				activitiesList: [],
				
			};
		},
		created() {
			let {current}=this.$route.query;
			this.current=current?Number(current):0;
			this.getBank();
		},
		mounted() {
			
		},
		methods: {
			animate(e){
				let target=e.target;
				let tagName=target.tagName.toLowerCase()
				if(tagName=='svg'){
					target.classList.add('animate__manimated');
					let time=setTimeout(()=>{
						clearTimeout(time)
						target.classList.remove('animate__manimated')
					},300)
				}
			},
			getBank(){
				getBank({}).then(({code,msg,data})=>{
					if(code==0 && !Array.isArray(data)){
						this.contaDeLevantamentoFromDta=data;
					}else if(data && !data.length){
						this.contaDeLevantamento=false
					}
				})
			},
			withdraw(form){
				withdraw({...form}).then(({code,msg,data})=>{
					if(code!=0){
						Toast.fail({
							message:this.myLineBreaks(msg),
							duration:1000
						});
					}else{
						Toast.success({
							message:this.myLineBreaks(msg),
							duration:1000
						});
					}
					//Toast(msg);
					if(form.isConter){
						setTimeout(()=>{
							location.reload();
						},1000)
						return false;
					}
					// isConter:true,
					this.getBalance();
				})
			},
			modelValue(e){
				this.current=e;
			},
			areSixDigitsTheSame(str) {
				const regex = /(\d)\1{5}/;
				return regex.test(str);
			},
			newPassword(e) {
				this.isShowPasword = this.areSixDigitsTheSame(e)
				this.new_password = e;
			},
			
			openUrl(e) {
				if (e.type == 'direct') {
					if (e.jump_url && e.jump_url != '#') {
						this.$router.push({
							name: e.jump_url
						})
					}
					// 跳转类型：direct直接跳URL，rich跳页面展示富文本（标题和内容）
				} else {
					this.$router.push({
						name: 'details',
						query: {
							title: e.title,
							content: e.content,
						}
					})
				}
			},
			onLoad() {
				// 异步更新数据
				// setTimeout 仅做示例，真实场景中一般为 ajax 请求
				setTimeout(() => {
					for (let i = 0; i < 10; i++) {
						this.list.push(this.list.length + 1);
					}

					// 加载状态结束
					this.loading = false;

					// 数据全部加载完成
					if (this.list.length >= 40) {
						this.finished = true;
					}
				}, 1000);
			},
		},
	};
</script>
<style>
	.gD0G2pAAJZZTJ1n0KQXv .van-icon {
		display: none;
	}

	.gD0G2pAAJZZTJ1n0KQXv .van-search__content {
		padding-left: .1rem;
	}
</style>
<style scoped>
	.DPP1EFmWKVe_UzDwWXH8{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.DPP1EFmWKVe_UzDwWXH8 .ant-btn{
		flex: 1;
	}
	.S7NE4JBCHiQzgcmndDQc{
		margin-right: .2rem;
	}
	.uh6csli2MHzFz87__Phx {
	    height: .32rem;
	    left: var(--cu-left);
	    position: absolute;
	    right: var(--cu-right);
	    top: var(--cu-top);
	    width: -webkit-fit-content;
	    width: -moz-fit-content;
	    width: fit-content;
	    z-index: 100
	}
	
	.uh6csli2MHzFz87__Phx p {
	    margin-bottom: 0
	}
	
	.uh6csli2MHzFz87__Phx .YiNQnpSJUes7u5sqBZhp {
	    border-bottom: .07rem solid transparent;
	    border-left: .07rem solid var(--theme-secondary-color-error);
	    border-right: .07rem solid transparent;
	    height: 0;
	    width: 0
	}
	
	.uh6csli2MHzFz87__Phx .T5xNMynUJQws22ZME9hV {
	    border-bottom: .07rem solid transparent;
	    border-left: .07rem solid transparent;
	    border-right: .07rem solid var(--theme-secondary-color-error);
	    float: right
	}
	
	.uh6csli2MHzFz87__Phx .sNgyWmhVEZVrbSGKDBra {
	    background-color: var(--theme-secondary-color-error);
	    border-radius: .125rem .125rem .125rem 0;
	    color: #fff;
	    font-size: .14rem;
	    height: .22rem;
	    line-height: .22rem;
	    padding: 0 .08rem;
	    width: 100%
	}
	
	.uh6csli2MHzFz87__Phx .sNgyWmhVEZVrbSGKDBra:lang(my_MM) {
	    height: .26rem
	}
	
	.uh6csli2MHzFz87__Phx .CmN8CVsfet8ieqBIT7mi {
	    color: #ff0
	}
	
	.uh6csli2MHzFz87__Phx .nc_NIDTLmXeUSFFfADOl {
	    border-radius: .125rem .125rem 0 .125rem
	}
	.whKvP0fKErR_J8VBaFio{
		font-size: .24rem;
		    height: .7rem;
			    border-radius: .14rem;
				
				line-height: .36rem;
				font-weight: 300;
				font-size: .24rem;
	}
	.Cod2k0E5Pzgd4QEEusl7 {
		display: block;
		font-size: .22rem;
		padding-bottom: .28rem;
		border-bottom: thin solid var(--theme-color-line);
		color: var(--theme-text-color);
		
	}

	.Ou1aviPriFX421w97dGA {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: var(--theme-text-color);
		font-size: .22rem;
		height: .32rem;
		margin-top: .3rem;
		margin-bottom: .15rem;
	}

	.Ou1aviPriFX421w97dGA .ZaAWGnvFuhPwlBk40od_ {
		color: var(--theme-color-line);
	}

	.Ou1aviPriFX421w97dGA .isShowNum .ZaAWGnvFuhPwlBk40od_ {
		color: var(--theme-primary-color);
	}

	.HZbJszKnd2frlT9r2NxC {
		color: var(--theme-primary-color);
		margin-left: .1rem;
	}

	.hypBigUdukqlsd7JuW8b {
		padding: .3rem .2rem 0;
	}

	.gD0G2pAAJZZTJ1n0KQXv {
		margin-bottom: .5rem;
	}

	.xyst1Xgxws_PsqrHlm4A {
		display: flex;
		align-items: center;
	}


	.tenSlis {
		color: var(--theme-primary-color);
		font-size: .45rem;
		height: .45rem;
		width: .45rem;
		margin-left: .2rem;
	}

	.C3g78JbIzYOGJevcEFJk {
		-ms-flex-pack: justify;
		background-color: var(--theme-main-bg-color);
		height: auto;
		justify-content: space-between;
		padding: .2rem;
		box-sizing: border-box;
		width: 100%
	}

	.C3g78JbIzYOGJevcEFJk .w8hvCePe2cr4lZDHWfAo {
		-ms-flex-pack: justify;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		font-size: .22rem;
		justify-content: space-between
	}

	.C3g78JbIzYOGJevcEFJk .w8hvCePe2cr4lZDHWfAo>p {
		-ms-flex-align: center;
		align-items: center;
		line-height: .35rem
	}

	.C3g78JbIzYOGJevcEFJk .w8hvCePe2cr4lZDHWfAo>p>span {
		line-height: .35rem
	}

	.C3g78JbIzYOGJevcEFJk .w8hvCePe2cr4lZDHWfAo .AZ93sQuJsiyisJNOP80k {
		color: var(--theme-text-color-lighten);
		font-size: .24rem;
		margin-bottom: .15rem
	}

	.C3g78JbIzYOGJevcEFJk .w8hvCePe2cr4lZDHWfAo .AZ93sQuJsiyisJNOP80k .LzGV_Y6Kh_gIgFmxJ9M3 {
		color: var(--theme-secondary-color-finance) !important;
		font-size: .24rem;
		font-weight: 700;
		margin-left: .05rem;
		margin-right: .1rem;
		position: relative;
		top: 0
	}

	.C3g78JbIzYOGJevcEFJk .w8hvCePe2cr4lZDHWfAo .AZ93sQuJsiyisJNOP80k .Gn5USDlcXrykBFgJY_Lc {
		color: var(--theme-primary-color);
		font-size: .3rem;
		height: .3rem;
		width: .3rem;
	}

	.C3g78JbIzYOGJevcEFJk .w8hvCePe2cr4lZDHWfAo .TBEN6ItimocPXMsKC0So {
		text-align: left;
		white-space: normal
	}

	.C3g78JbIzYOGJevcEFJk .w8hvCePe2cr4lZDHWfAo .TBEN6ItimocPXMsKC0So span {
		color: var(--theme-text-color-lighten)
	}

	.C3g78JbIzYOGJevcEFJk .w8hvCePe2cr4lZDHWfAo .TBEN6ItimocPXMsKC0So .Zoa_YKjt9A5jczOFotnl {
		border-bottom: thin solid var(--theme-primary-color);
		color: var(--theme-primary-color)
	}

	.C3g78JbIzYOGJevcEFJk .w8hvCePe2cr4lZDHWfAo .TBEN6ItimocPXMsKC0So .O_aIJGV00l8icMkw8dGQ>i {
		margin: 0 .05rem
	}

	.C3g78JbIzYOGJevcEFJk .N0JQr4MRsNlYA1VIq2VB {
		color: var(--theme-text-color-lighten);
		font-size: .24rem;
		line-height: .35rem
	}

	.C3g78JbIzYOGJevcEFJk .N0JQr4MRsNlYA1VIq2VB>p {
		margin-bottom: .01rem
	}

	.C3g78JbIzYOGJevcEFJk .N0JQr4MRsNlYA1VIq2VB .Q6J2UULA0fgYUpJzLVu6 {
		color: var(--theme-text-color-darken);
		font-weight: 700;
		margin-left: .05rem
	}

	.C3g78JbIzYOGJevcEFJk .N0JQr4MRsNlYA1VIq2VB .drePilqDntTa5TlrjiiD {
		font-size: .2rem;
		height: auto;
		max-width: 2.8rem;
		min-height: .5rem;
		min-width: 1.2rem
	}

	.C3g78JbIzYOGJevcEFJk .N0JQr4MRsNlYA1VIq2VB .drePilqDntTa5TlrjiiD>span {
		word-wrap: break-word;
		white-space: normal;
		word-break: break-word
	}

	.C3g78JbIzYOGJevcEFJk .DOVieBMgJWhGlNfqz8mX .Q6J2UULA0fgYUpJzLVu6 {
		color: var(--theme-secondary-color-finance)
	}
</style>
